import { useCallback, useEffect, useState, useRef } from "react";
import { urls } from "../urls";
import { UserInfo, UserRow } from "interfaces/hooks";
import { usersTransfrom } from "utils";
import { OriGroup, OriRfp } from "utils";
import { Group } from "interfaces/config";

interface UserListCountBody {
    AttributesToGet?: [string] | undefined
    Filter?: string | undefined
    Limit?: number | undefined
    PaginationToken?: string | undefined
    group?: string | string[] | undefined
}

export const useUserManagement = (userRole, currentStatus, currentSecurityLevel, searchParams) => {
    //const [paginationToken, setPaginationToken] = useState<string | undefined>()
    const [mappedData, setUserMangementData] = useState<Array<UserRow>>([])
    const [totalRecords, setTotalRecords] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const users: Array<any> = []
    const listUsers: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/listUsers`
    const getUserCount: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/getUserCount`
    const STATE = process.env.REACT_APP_CONFIG_STATE!
    const runCount = useRef(0);


    useEffect(() => {
        console.log('userManagementData: ', mappedData)
        setTotalRecords(mappedData.length)
    }, [mappedData.length])


    useEffect(() => {
        runCount.current = runCount.current + 1;
        if (userRole) fetchUsers()
    }, [searchParams, userRole])

    /* Currently no need for a count but this will likely change in the future.
    const fetchUserCount = async () => {

        try {
            //setLoading(true)
            const data: any = await (await fetch(getUserCount, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({})
            })).json();


            setTotalRecords(data.userCount)
            return
        } catch (e: unknown) {
            console.log('userManagement caught error: ', e)
        }
    }*/

    const fetchUsers = async () => {

        const body: UserListCountBody = {
            group: userRole
        }
        setLoading(true)

        try {
            const data: any = await (await fetch(listUsers, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json();

            const transformedData = data.map((user: UserInfo, index: number) => {
                return usersTransfrom(user, index, searchParams)
            }).filter((el) => {
                return el != undefined
            })

            users.push(...transformedData)

            if (searchParams.length > 0) {
                setUserMangementData(transformedData)
            } else {
                setUserMangementData(users)
            }

            console.log('debug simple search listUsers useUserManagement catch users: ', users)
            setLoading(false)
            return
        } catch (e: unknown) {
            console.log('userManagement caught error: ', e)
        }
    }

    const updateUserGroup = async (groupName: string, user: string) => {
        console.log(`debug updateUserGroup running ${JSON.stringify({ groupName, user }, null, 2)}`)
        const addUserToGroup: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/addUserToGroup`
        const body = {
            "GroupName": groupName,
            "Username": user
        }


        let resp
        try {
            resp = await (await fetch(addUserToGroup, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp.message)
        }

        return resp
    }

    const updateUser = useCallback(async (formState: any) => {

        console.log('debug updateUser formState: ', formState)
        const updateUser: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/updateUser`

        console.log(`debug updateUser currentStatus ${currentStatus} currentSecurityLevel ${currentSecurityLevel}`)
        const { firstName, lastName, email, userName, oriRfpGroups, status, securityLevel } = formState

        if (status !== currentStatus) {
            console.log('debug updateUser status updated!')
            updateUserStatus(status, userName)
        }

        if (securityLevel !== currentSecurityLevel) {
            console.log('debug updateUser role reverse lookup: ', Group[securityLevel])
            updateUserGroup(Group[securityLevel], userName)
        }

        console.log('debug updateUser oriRfpGroups: ', JSON.stringify(oriRfpGroups))

        const customOri: Array<{ ori: string, rfp: Array<string> }> = []
        const customSori: Array<{ sori: string, rfp: Array<string> }> = []

        oriRfpGroups.forEach((group: OriGroup) => {
            if (group.hasOwnProperty("primaryOri")) {
                customOri.push({ ori: group.primaryOri!.value, rfp: [group.rfp.value || "ALL"] })
            } else {
                customSori.push({ sori: group.secondaryOri!.value, rfp: [group.rfp.value || "ALL"] })
            }
        })

        console.log('debug updateUser customOri: ', JSON.stringify(customOri))
        console.log('debug updateUser customSori: ', JSON.stringify(customSori))

        const body = {
            "Username": userName,
            "UserAttributes": [
                {
                    "Name": "email",
                    "Value": email
                },
                {
                    "Name": "family_name",
                    "Value": lastName
                },
                {
                    "Name": "given_name",
                    "Value": firstName
                },
                STATE === "nm" ? {
                    "Name": "custom:ori-long",
                    "Value": JSON.stringify(customOri)
                } : {
                    "Name": "custom:ori",
                    "Value": JSON.stringify(customOri)
                },
                STATE === "me" && {
                    "Name": "custom:secondary-ori",
                    "Value": JSON.stringify(customSori)
                }
            ].filter(Boolean)
        }

        console.log('debug updateUser body: ', body)

        let resp;
        setLoading(true)
        try {
            resp = await (await fetch(updateUser, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp)

        } catch (e: any) {
            console.log('Error updating user: ', e)
        }
        setLoading(false)
        return resp
    }, [currentStatus, currentSecurityLevel])

    const updateUserStatus = async (status: boolean, user: string) => {
        console.log('debug updateUser status updated!')

        console.log(`debug updateUser updateUserStatus running ${JSON.stringify({ status, user }, null, 2)}`)
        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/manageUserStatus`

        let body = {
            "Username": user,
            "Enable": status
        }

        console.log('updateUserGroup called with body: ', JSON.stringify(body, null, 2))

        let resp
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp.message)
        }

        return resp
    }

    const createUser = async (userName: string, email: string, lastName: string, firstName: string, oriRfpGroup: [{ primaryOri: string, rfp: string }], status: string, securityLevel: string) => {
        console.log('formState in createUser')
        const createUser: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/createUser`
        const oriRfp = oriRfpGroup[0]
        const STATE = process.env.REACT_APP_CONFIG_STATE!

        const customOri: Array<{ ori: string, rfp: Array<string> }> = []
        const customSori: Array<{ sori: string, rfp: Array<string> }> = []

        oriRfpGroup.forEach((group: any) => {
            if (group.hasOwnProperty("primaryOri")) {
                customOri.push({ ori: group.primaryOri!.value, rfp: [group.rfp.value || "ALL"] })
            } else {
                customSori.push({ sori: group.secondaryOri!.value, rfp: [group.rfp.value || "ALL"] })
            }
        })

        console.log('debug createUser customOri: ', JSON.stringify(customOri))
        console.log('debug createUser customSori: ', JSON.stringify(customSori))

        const body = {
            addGroup: securityLevel,
            createUser: {
                Username: userName,
                UserAttributes: [
                    {
                        "Name": "email",
                        "Value": email
                    },
                    {
                        "Name": "family_name",
                        "Value": lastName,
                    },
                    {
                        "Name": "given_name",
                        "Value": firstName,
                    },
                    {
                        "Name": "custom:access",
                        "Value": "mc"
                    },
                    STATE === "nm" ? {
                        "Name": "custom:ori-long",
                        "Value": JSON.stringify(customOri)
                    } : {
                        "Name": "custom:ori",
                        "Value": JSON.stringify(customOri)
                    },
                    STATE === "me" && {
                        "Name": "custom:secondary-ori",
                        "Value": JSON.stringify(customSori)
                    }
                ].filter(Boolean)
            }
        }

        let resp
        try {
            resp = await (await fetch(createUser, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()
            console.log('createUser resp: ', resp)
            return resp
        } catch (e) {
            console.log('Error creating user: ', resp.message)
        }
    }

    const resetPassword = async (user: string) => {
        console.log('debug updateUser status updated!')

        console.log(`debug updateUser updateUserStatus running ${JSON.stringify({ user }, null, 2)}`)
        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/resetUserMFA`

        let body = {
            "Username": user
        }

        console.log('updateUserGroup called with body: ', JSON.stringify(body, null, 2))

        let resp
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp.message)
        }

        return resp
    }

    const resendTempPassword = async (status: string, user: string) => {
        console.log('debug updateUser status updated!')

        console.log(`debug updateUser updateUserStatus running ${JSON.stringify({ status, user }, null, 2)}`)
        const updateUserStatus: string = `${process.env.NODE_ENV === "production" ? urls.USER_ADMIN : ""}/userAdmin/resendTempPassword`

        let body = {
            "Username": user
        }

        console.log('updateUserGroup called with body: ', JSON.stringify(body, null, 2))

        let resp
        try {
            resp = await (await fetch(updateUserStatus, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })).json()

            console.log('addUserToGroup resp: ', resp)
        } catch (e) {
            console.log('Error setting group: ', resp.message)
        }

        return resp
    }


    return { loading, error, mappedData, runCount: runCount.current, totalRecords, updateUserGroup, createUser, updateUserStatus, updateUser, resetPassword, resendTempPassword }
}