/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { redirect } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { MFAPasswordReset } from "../../components/MFA-PasswordReset/MFAPasswordReset";
import {
  Box,
  CircularProgress,
  Button,
  TextField,
  IconButton,
  CardHeader,
  Alert,
  AlertTitle,
  Grid,
  Typography,
  CardContent,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useMUITheme } from "../../theme/ThemeProvider";
import { LoginStyles } from "customStyles/index";
import { useLoginStore } from "state/LoginStore";
import { getBackgroundColor, getBorderColor, getIconColor } from "customStyles/common";
import { errorTypes } from '../../utils/errorTypes';
import useCookies from "react-cookie/cjs/useCookies";
import { Buffer } from 'buffer';
import { useTableStore, useUserStore } from "../../state";
import { Config } from "interfaces/config";
import dayjs from 'dayjs'
import { TableNames } from "customEnums/TableNames";
import lightLogo from '../../assets/logo/IDEMIA-purple.png';
import darkLogo from '../../assets/logo/IDEMIA-white.png';

export const Login = () => {
  const [{ userName, password }, setFields] = useState<any>({
    userName: "",
    password: "",
  });
  const [cookies, setCookie, removeCookie] = useCookies();
  const { mode } = useMUITheme();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [user_name, setUserName] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [startMFAFlow, setStartMFAFlow] = useState(false);
  const [stateLogo, setStateLogo] = useState('')
  const [pwError, setPwError] = useState(false);
  const inputRef = useRef<any>(null);
  const [userNameError, setUserNameError] = useState(false);
  const sessionExpired = sessionStorage.getItem("Session expired");
  const isDark = mode === 'dark';

  // Table Store:
  const alertTitle = useLoginStore((state) => state.alertTitle)
  const setAlertTitle = useLoginStore((state) => state.setAlertTitle)
  const alertDescription = useLoginStore((state) => state.alertDescription)
  const setAlertDescription = useLoginStore((state) => state.setAlertDescription)
  const severity = useLoginStore((state) => state.severity)
  const setSeverity = useLoginStore((state) => state.setSeverity)
  const openAlert = useLoginStore((state) => state.openAlert)
  const setOpenAlert = useLoginStore((state) => state.setOpenAlert)

  const STATE = process.env.REACT_APP_CONFIG_STATE!;
  const STAGE = process.env.REACT_APP_STAGE!;
  console.log('debug refactor config STATE declares', STATE)

  let preauth_token = "SCOPED_TOKEN";

  const alertStyle = {
    backgroundColor: getBackgroundColor(severity),
    mb: '1rem',
    borderColor: getBorderColor(severity),
    border: `1px solid`,
    '.MuiAlert-icon': {
      color: getIconColor(severity),
    },
    '& .MuiAlert-action': {
      color: sessionExpired ? 'warning.contrastText' : '',
    },
    width: '100%'
  };

  const setTableConfig = useTableStore(state => state.setTableConfig)

  const setLoadingConfig = useTableStore(state => state.setLoadingConfig)
  const [maintenanceSchedule, setMaintenanceSchedule] = useState<any>({ maintenanceActive: false })

  const userConfig = useUserStore(state => state.userConfig)
  const setConfig = useUserStore(state => state.setConfig)
  const { loggedIn } = userConfig

  const getMaintenanceSchedule = (config) => {

    const startDate = config.scheduledMaintenance?.startDate
    const endDate = config.scheduledMaintenance?.endDate;
    const currentDate = dayjs().format('MM-DD-YYYY HH:mm:ss');
    let maintenanceActive;

    if (startDate && endDate) {
      if (currentDate >= startDate && currentDate <= endDate) {
        maintenanceActive = true
        setSeverity("warning")
        setOpenAlert(true)
        setAlertTitle("Maintenance Scheduled.")
        setAlertDescription("This website is under maintenance, please check back later.")
      } else {
        maintenanceActive = false
      }
    }
    setMaintenanceSchedule({ startDate, endDate, maintenanceActive, currentDate })
  }

  const getConfig = useMemo(() => async () => {
    console.log('debug getConfig running')
    try {
      setLoadingConfig(true)

      const response = await fetch(`../../config_${STATE}.json`);
      const data: Config = await response.json();

      setConfig(data)

      const {
        cognito,
        transactionViewing,
        nistParsing,
        pages
      } = data

      const { userRoles } = cognito

      setLoadingConfig(false);

      if (response.ok) {

        getMaintenanceSchedule(data)

        const tableConfig = {
          [TableNames.TRANSACTION_SUMMARY]: {
            transactionViewing,
            nistParsing,
          }

          //userRoles, move to userConfig
        }

        setTableConfig(tableConfig)

      }
    } catch (err) {
      console.error(err);
    }

  }, []);

  useEffect(() => {
    console.log('debug getConfig in login useEffect')
    setLoadingConfig(true)
    getConfig()
  }, []);

  useEffect(() => {
    inputRef.current!.focus({
      cursor: "start",
    });
  }, []);


  useEffect(() => {
    if (sessionExpired) {
      setSeverity("warning")
      setOpenAlert(true)
      setAlertTitle('Session Expired')
      setAlertDescription("Your session is expired. Please log back in.")
    }
  }, [sessionExpired])

  useEffect(() => {
    console.log("STATE BEFORE TRY", STATE)
    const getConfig = async () => {
      try {
        console.log("STATE AFTER TRY", STATE)
        const response = await fetch(`../config_${STATE}.json`);
        const data = await response.json();
        console.log('whats in the data', data)
        let logo = data.logo.logo;
        if (STAGE === 'demo') {
          logo = data.states['nm'].logo;
        }
        setStateLogo(logo)
      } catch (err) {
        console.error(err);
      }
    };

    getConfig();

  }, [STATE]);

  useEffect(() => {
    const setPreAuthToken =
      async () => {
        try {
          const clientID = process.env.REACT_APP_PREAUTH_CLIENT_ID!
          const clientPassword = process.env.REACT_APP_PREAUTH_CLIENT_SECRET!
          const url = `${process.env.REACT_APP_COGNITO_DOMAIN!}/oauth2/token`
          const scope = `${process.env.REACT_APP_STAGE}-resource-server-id/PreAuthScope`
          let params = new URLSearchParams();
          params.append('grant_type', 'client_credentials');
          params.append('client_id', clientID);
          params.append('scope', scope);
          const resp = await fetch(url, {
            method: 'POST',
            body: params,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded',
              'Authorization': `Basic ${encode(`${clientID}:${clientPassword}`)}`
            },
          })
          let jsonResponse = await resp.json();
          const access_token = jsonResponse.access_token
          preauth_token = access_token
          const yes = setCookie("token", `${access_token}`, {
            path: "/",
            expires: expDate,
            sameSite: "lax",
            domain: process.env.REACT_APP_DOMAIN_NAME!
          });
        } catch (err) {
          console.error(`Error getting pre auth token ${err}`);
        }
      }
    setPreAuthToken();
  }, [STATE]);


  const updateField = (e: any) => {
    const { name, value } = e.target;
    setFields((currentValues: any) => ({ ...currentValues, [name]: value }));
    setErrorMessage(null);
  };

  const handleUserNameBlur = (e: any) => {
    setUserNameError(e.target.value === '' ? true : false);
  }

  const handlePWBlur = (e: any) => {
    setPwError(e.target.value === '' ? true : false);
  }

  const {
    login,
    mfa,
    userForgotPassword,
    forceResetPassword,
    handleResetPassword
  } = useAuth();

  const handleSubmit = useCallback(async (e: any) => {
    console.log(`debug login handleSubmit userName: ${userName} password: ${password} ran e: `, e);
    e.preventDefault();
    setLoading(true);
    let res;
    res = await login?.({ userName, password });
    setLoading(false);
    if (res.username) {
      console.log("login success ", res);
      setStartMFAFlow(true);
      setOpenAlert(false)
      sessionStorage.clear();
    } else {
      console.log("error: ", res);
      setSeverity("error")
      setOpenAlert(true)

      const errorMessage = res?.errorMessage;
      const errorCode = res?.errorCode;

      if (errorTypes[errorMessage]) {
        setAlertTitle(errorTypes[errorMessage].title);
        setAlertDescription(errorTypes[errorMessage].description);
      } else if (errorTypes[errorCode]) {
        setAlertTitle(errorTypes[errorCode].title);
        setAlertDescription(errorTypes[errorCode].description);
      } else {
        setAlertTitle(errorMessage || 'An unknown error occurred.');
        setAlertDescription('');
      }
    }
  }, [getConfig, userName, password]);

  useEffect(() => {
    if (loggedIn) redirect("/app/transaction-summary");
  }, [loggedIn]);

  useEffect(() => {
    if (!userForgotPassword && !forceResetPassword) setStartMFAFlow(false);
    if (mfa || userForgotPassword || forceResetPassword) setStartMFAFlow(true);
  }, [userForgotPassword, forceResetPassword, mfa]);

  const getUserName = () => {
    setUserName(true)
    setStartMFAFlow(true);
    setOpenAlert(false)
    setSeverity("")
  };

  const pressForgotPW = (e: any) => {
    setUserName(true);
    setOpenAlert(false)
    setSeverity("")
    setStartMFAFlow(true);
  }

  const resetPassword = async (
    finalPassword: string,
    code = null,
    userName: string
  ) => {
    let res = await handleResetPassword?.(finalPassword, code, userName);
    if (res) {
      setStartMFAFlow(false);
      setUserName(false);
      setSeverity("success")
      setOpenAlert(true)
      setAlertTitle("Password Updated!")
      setAlertDescription("Please Login using your new password.")
    }
  };

  const cancel = (e: boolean) => {
    setUserName(e)
    setStartMFAFlow(e);
    console.log("has been cancelled");
  };

  const encode = (str: string): string =>
    Buffer.from(str, 'binary').toString('base64');

  const expDate = useMemo(() => {
    return new Date("2024-11-29T12:53:50.000Z");
  }, []);

  useEffect(() => {
    console.log(
      `login errorMessage: ${errorMessage} startMFAFlow: ${startMFAFlow}`
    );
  }, [errorMessage, startMFAFlow, token]);

  const closeSessionExpired = () => {
    setOpenAlert(false);
    setSeverity("")
    setAlertTitle('')
    setAlertDescription("")
    sessionStorage.clear();
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  console.log('debug mode: ', mode)

  function formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}${month}${day}`;
  }

  function createRapbackSchemaFunction(tenantId: string) {
    const today = new Date();
    const oneDayLater = new Date(today.getTime() + (24 * 60 * 60 * 1000)); // Adding 1 day

    const subscriptionDate = formatDate(oneDayLater);

    const expirationDate = new Date(oneDayLater);
    expirationDate.setMonth(expirationDate.getMonth() + 38);

    const expirationDateString = formatDate(expirationDate);
    console.log('subscriptionDate', subscriptionDate, 'expirationDateString', expirationDateString);

  }

  createRapbackSchemaFunction('1234');

  return (
    <>
      {(
        !startMFAFlow ? (
          <>
            <CardHeader
              sx={{ width: '31.5rem' }}
              title='Sign In'
              titleTypographyProps={{ variant: 'h5', color: 'text.primary', mt: '0.5rem' }}
              subheader={`Enter your credentials to access the ${stateLogo} Portal.`} 
              subheaderTypographyProps={{ variant: 'body2', color: 'text.secondary', mt: '0.25rem' }} />
            <Box component="form">
              <CardContent sx={LoginStyles.mfaCardContentStyle}>
                {openAlert &&
                  <Alert severity={severity} sx={alertStyle} onClose={sessionExpired ? closeSessionExpired : undefined} >
                    <AlertTitle color={getIconColor(severity)}>
                      {alertTitle}
                    </AlertTitle>
                    <Typography color={getIconColor(severity)} variant='body2'>
                      {alertDescription}
                    </Typography>
                  </Alert>
                }
                <TextField
                  onChange={updateField}
                  onBlur={handleUserNameBlur}
                  variant={mode === 'dark' ? "filled" : "outlined"}
                  required
                  fullWidth
                  id="userName"
                  label="Username"
                  name="userName"
                  autoComplete="username"
                  aria-label="username"
                  error={userNameError}
                  helperText={userNameError && "Username is required."}
                  autoFocus
                  InputLabelProps={{ sx: { color: userNameError ? 'red' : 'text.secondary' } }}
                  InputProps={{ sx: { color: 'text.primary' } }}
                  inputRef={inputRef}
                  disabled={maintenanceSchedule.maintenanceActive}
                />
                <TextField
                  onChange={updateField}
                  onBlur={handlePWBlur}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  aria-label='password'
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  error={pwError}
                  helperText={pwError ? "Password is required." : ''}
                  variant={mode === 'dark' ? "filled" : "outlined"}
                  InputLabelProps={{ style: { color: 'text.secondary' } }}
                  disabled={maintenanceSchedule.maintenanceActive}
                  InputProps={{
                    style: { color: 'text.primary' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Toggle Password Visibility">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            style={{ color: '#808086' }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </Tooltip>

                      </InputAdornment>
                    )
                  }}
                />
                <div tabIndex={0} style={{ width: '100%' }}>
                  <Button
                    fullWidth
                    onClick={handleSubmit}
                    disabled={(userName && password) && !maintenanceSchedule.maintenanceActive ? false : true}
                    color="secondary"
                    variant="contained"
                    type="submit"
                    tabIndex={2}
                    aria-disabled={(userName && password) ? false : true}
                    aria-live="assertive"
                    aria-label='sign in'
                    size="large"
                  >
                    {!loading ? 'SIGN IN' : <CircularProgress size='1.5rem' sx={{ marginLeft: '0.5rem', color: '#fff' }} />}
                  </Button>

                </div>
                <Grid item xs={12}>
                  {/* <Typography variant="caption" color='text.primary'>
                    By signing into this application, you agree to the following
                  </Typography>
                  <Typography variant='caption' color='primary'> Terms and Conditions</Typography>
                  <Typography variant='caption'color='text.primary'> and</Typography>
                  <Typography variant='caption' color='primary'> Privacy Policy</Typography> */}
                </Grid>
                <Grid item xs={12} >
                  <Typography
                    variant="body2"
                    color='primary'
                    onClick={maintenanceSchedule.maintenanceActive ? () => { } : getUserName}
                    onKeyPress={pressForgotPW}
                    tabIndex={0}
                    sx={{ textDecoration: 'underline', '&:hover': { cursor: maintenanceSchedule.maintenanceActive ? 'not-allowed' : 'pointer' } }}>
                    Forgot Password?
                  </Typography>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' direction='column' mt='0.5rem'>
                  <Typography variant="h3" color='text.primary' sx={{ mb: '0.5rem' }}>{stateLogo}</Typography>
                  <img src={isDark ? darkLogo : lightLogo} style={{width: '86px' }}/>
                </Grid>
              </CardContent>
            </Box>
          </>
        ) : (
          <MFAPasswordReset
            cancel={cancel}
            resetPassword={resetPassword}
            userName={user_name}
            stateLogo={stateLogo}
          />
        )
      )}
    </>
  );
};
