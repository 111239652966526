import * as React from 'react';
import { pageStyles } from "customStyles/common";
import { Paper } from "@mui/material";
import { ConfirmModal, CustomTable, CustomTableTitleAndAlert } from "../../../common";
import { maintenanceMockData, prenotificationMockData } from "../rapbackSubMockData";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Order } from "@customTypes/OrderType";
import { ColumnState, useTableStore, useUserStore } from "../../../state";
import { useExpanded } from "../../../components/layouts/ProtectedLayout";
import { useLocation } from "react-router-dom";
import { extractPageName } from "../../../common/CustomTable/helpers";
import { useRapbackSubscription } from "../../../hooks/useRapbackSubscription";
import { searchReducer } from "../../TransactionSummary/helpers/searchReducer";
import { AlertColor } from 'customEnums/AlertColor';
import { useRapbackMaintenanceStore } from '../RapbackMaintenance/state/MaintenanceStore'
import { useAudit } from "hooks/useAudit";
import { generateTableConfig, usersTransfrom } from 'utils';
import { useConfigService, useSetConfig } from 'hooks';
import { TableNames } from 'customEnums/index';
import { savedSearchesReducer } from 'pages/TransactionSummary/helpers/savedSearchesReducer';

export const RapbackPrenotifications: React.FC = () => {
    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const { addAuditEvent } = useAudit();

    //Local State
    const [data, setData] = useState<[]>([])
    const [sortOrder, setSortOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>('startDate');
    const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
    const [value, setValue] = React.useState<any>([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [confirmModalTitle, setConfirmModalTitle] = useState("")
    const [confirmModalContent, setConfirmModalContent] = useState("")
    const [cancelButtonText, setCancelButtonText] = useState("")
    const [confirmButtonText, setConfirmButtonText] = useState("")
    const [action, setAction] = useState("")
    const [rapbackId, setrapbackId] = useState("")
    const [displaySSN, setDisplaySSN] = useState('')
    const [showSSN, setShowSSN] = useState<boolean>(false);
    //Hooks
    const { expanded } = useExpanded()
    const userConfig = useUserStore(state => state.userConfig)

    const { userORIs, userRole, userSecondaryORIs, usersPermissions } = userConfig;
    const [formState, dispatchFormValues] = useReducer(searchReducer, { firstName: '' })

    // Rapback maintenance store
    const setFullName = useRapbackMaintenanceStore(state => state.setFullName)

    //Table Store
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore((state) => state.pagination)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const openNotification = useTableStore((state) => state.openNotification)
    const searchParams = useTableStore((state) => state.searchParams)

    const isPost = useTableStore((state) => state.isPost)
    const isDelete = useTableStore((state) => state.isDelete)
    const configFile = useTableStore((state) => state.configFile)
    const configBody = useTableStore((state) => state.configBody)
    const { searchData, alertData, postedConfig } = useConfigService(configFile, configBody, true, isPost, isDelete);

    const loadedData = React.useMemo(() => ({ ...searchData }), [searchData]);
    const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);

    const setTableConfig = useTableStore(state => state.setTableConfig)
    const tableConfig = useTableStore(state => state.tableConfig)
    const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)
    const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)


    const handleAcceptSubscription = (e, row) => {
        setrapbackId(row.rapbackSubId)
        setAction("accept_subscription")
        setConfirmModalTitle("CONFIRM ACCEPT REQUEST")
        setConfirmModalContent(`Are you sure you want to accept the Rapback Subscription request
        for ${row.fullName}? This applicant will receive Rapbacks.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("ACCEPT REQUEST")
        setOpenConfirmModal(true)
        addAuditEvent("Accepted Pre-notification Request", "Rapback Pre-notification", new Date(), row.rapbackId)
        setFullName(row.fullName)
    }

    const handleDeclineSubscription = (e, row) => {
        setrapbackId(row.rapbackSubId)
        setAction("deny_subscription")
        setConfirmModalTitle("CONFIRM DECLINE REQUEST")
        setConfirmModalContent(`Are you sure you want to decline the Rapback Subscription request
        for ${row.fullName}? This applicant will no longer receive Rapbacks. This action cannot be undone.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("DECLINE REQUEST")
        addAuditEvent("Declined Pre-notification Request", "Rapback Pre-notification", new Date(), row.rapbackId)
        setOpenConfirmModal(true)
        setFullName(row.fullName)
    }

    const actionsConfig: { actionLabel: string, actionHandler: Function }[] = [
        {
            actionLabel: "Accept Subscription",
            actionHandler: handleAcceptSubscription
        },
        {
            actionLabel: "Decline Subscription",
            actionHandler: handleDeclineSubscription
        }
    ]

    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.RAPBACK_PRENOTIFICATIONS, {}, actionsConfig)

    //Data
    console.log('run useRBSub: ', orderBy)
    const { data: mappedData, runCount, loading, totalRecords, handleConfirmPrenotification, handleDenyPrenotification } = useRapbackSubscription(pageName, rowsPerPage,
        page,
        sortOrder,
        orderBy,
        userORIs,
        userSecondaryORIs,
        userRole,
        searchParams, true, TableNames.RAPBACK_PRENOTIFICATIONS)

    console.log(`Prenotification data: `, mappedData)

    useEffect(() => {
        if (openNotification) {
            setTimeout(() => {
                setOpenNotification(false)
            }, 5000)
        }
    })

    useEffect(() => {
        setPagination({ ...pagination, [TableNames.RAPBACK_PRENOTIFICATIONS]: { page: 1, rowsPerPage: 25 } })
    }, [])


    useEffect(() => {
        setData(
            mappedData?.map((data: any, index) => {
                console.log('prenotifications setData data: ', data)
                return {
                    key: data.key,
                    fullName: data.fullName,
                    dateOfBirth: data.dateOfBirth,
                    ssn: data.ssn,
                    rapbackSubId: data.rapbackSubId, //is this rapback id?
                    primaryOri: data.primaryOri,
                    //secondaryOri: data.secondaryOri,
                    startDate: data.startDate,
                    expirationDate: data.expirationDate,
                    receivedDate: data.receivedDate
                }
            })
        );
    }, [mappedData?.length, mappedData])


    useEffect(() => {

        console.log('debug stuck search results useEffect: ', { runCount, searchParams, mappedData, simpleSearchRan, searched, setDisplayNoSearchResults: mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched })
        console.log('debug stuck search results useEffect: ', { conditional: runCount < 1 && searchParams.length > 0, negativeConditional: !(runCount < 1 && searchParams.length > 0) })
        if (runCount < 1 && searchParams.length > 0) {
            setDisplayNoSearchResults(false)
            setOpenNotification(false)
            return
        }
        if (mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched) {
            setDisplayNoSearchResults(true)
            setOpenNotification(true)
        }
        //console.log('debug open notification display no seasrch', displayNoSearchResults)
        console.log('debug open notification', openNotification)

    }, [mappedData?.length, simpleSearchRan, searched])

    //Potentially make generic and import
    const handleRequestSort = (event: any, property) => {
        console.log(`sort date handleReuqestSort sortOrder: ${sortOrder} orderBy ${orderBy} property: ${property}`)
        //const isAsc = sortOrder === "desc" ? false : orderBy === property && order === 'asc';
        const isAsc = orderBy === property && sortOrder === 'asc';
        console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
        setSortOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property);
    };

    const handleClickShowSSN = useCallback(async (record: string, e: any) => {
        if (displaySSN !== record) {
            setDisplaySSN(record)
            setShowSSN(!showSSN)

        } else {
            setDisplaySSN('')
            setShowSSN(!showSSN)
        }
    }, [displaySSN])

    return (
        <>
            <Paper elevation={0} sx={
                {
                    ...pageStyles.pagePaperStyle,
                    maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
                }}>
                <CustomTableTitleAndAlert name="Rapback Pre-Notifications" />
                {
                    tableHeaderCells && (
                        <CustomTable
                            //Required props
                            tableConfig={tableConfig}
                            tableName="rapback-prenotifications"
                            loading={loading}
                            rows={data}
                            orderBy={orderBy}
                            order={sortOrder}
                            totalRecords={totalRecords}
                            currentPage={page}
                            pageSize={rowsPerPage}
                            searched={searched}
                            value={value}
                            handleRequestSort={handleRequestSort}
                            setValue={setValue}
                            handleClickShowSSN={handleClickShowSSN}
                            displaySSN={displaySSN}
                            setSearched={setSearched}
                            //Per page props
                            defaultSortColumn="fullName"
                            rowClickHandler={(e, row) => { }}
                        />
                    )

                }

                <ConfirmModal
                    customWidth={'600px'}
                    customHeight={'193px'}
                    title={confirmModalTitle}
                    content={confirmModalContent}
                    cancelButtonText={cancelButtonText}
                    confirmButtonText={confirmButtonText}
                    handleCancel={() => setOpenConfirmModal(false)}
                    handleConfirm={async (e) => {
                        switch (action) {
                            case "accept_subscription":
                                setOpenConfirmModal(false)
                                handleConfirmPrenotification(rapbackId)
                                break;
                            case "deny_subscription":
                                setOpenConfirmModal(false)
                                handleDenyPrenotification(rapbackId)
                                break;
                        }
                    }}
                    openDialog={openConfirmModal}
                />
            </Paper>{ }
        </>
    )
}