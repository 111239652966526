import React, { useEffect } from "react";
import { Container } from "@mui/material";
import { CustomTable } from "common";
import { useTableStore } from "state/TableStore";
import { extractPageName } from "../../../common/CustomTable/helpers";
import { useLocation } from "react-router-dom";
import { TableNames } from "customEnums/TableNames";
import { useResultsResponse, useSetConfig } from "hooks";

export const RejectCodeTable: React.FC<any> = ({ registrationId, name }) => {

    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)

    const tableConfig = useTableStore(state => state.tableConfig)
    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 0, rowsPerPage: 5 }, TableNames.REJECT_CODES)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const { setLoadingRejectCodes, loadingRejectCodes, rejectData, totalRejectCodes } = useResultsResponse(registrationId as string);

    useEffect(() => {
        setPagination({ ...pagination, [TableNames.REJECT_CODES]: { page: 0, rowsPerPage: 5 } })
    }, [])

    useEffect(() => {
        console.log(`debug skeleton loading loadingRejectCodes: `, { loadingRejectCodes, rejectData })
        if (rejectData) {
            setLoadingRejectCodes(false)
        }
    }, [rejectData])

    return (
        <Container disableGutters maxWidth={false} sx={{ height: "95%", width: "100%" }}>

            {
                tableHeaderCells &&
                <CustomTable
                    //Required props
                    loading={loadingRejectCodes}
                    tableConfig={tableConfig}
                    tableName={name}
                    rows={rejectData}
                    hideSearch={true}
                    totalRecords={totalRejectCodes}
                    currentPage={page}
                    pageSize={rowsPerPage}
                />
            }

        </Container>
    )
}