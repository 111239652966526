import { DatePicker } from "@mui/x-date-pickers";
import { Container, Typography } from "@mui/material";
import * as React from "react";
import { EditSubscriptionAction } from "./RapbackMaintenance";
import dayjs, { Dayjs } from 'dayjs';
import { buildDateErrorMessage, DateErrorType } from '../../../utils/errorTypes';

const EditSubTextRow: React.FC<any> = ({ children }) => {
    return (
        <Container disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
            {children}
        </Container>
    )
}

const EditSubTextDisplay: React.FC<{ title: string, value: string }> = ({ title, value }) => {
    return (
        <Container disableGutters sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>{title.toUpperCase()}</Typography>
            <Typography>{value}</Typography>
        </Container>
    )
}

enum DateType {
    DOB = 'Date of Birth',
    EXPIRATION_DATE = 'Expiration Date'
}

export const EditSubscriptionForm: React.FC<any> = ({ formState, staticExpiration, staticDoB, setDisabled }) => {
    console.log('edit subscription formstate: ', formState)
    const { dob, expirationDate, fullName, rapbackSubId, ori, confirmation, status, startDate } = formState[0];
    const dispatchFormValues = formState[1];
    const [dobError, setDobError] = React.useState<boolean>(false);
    const [dobErrorMessage, setDobErrorMessage] = React.useState<string>();
    const [expirationError, setExpirationError] = React.useState<boolean>(false);
    const [expirationErrorMessage, setExpirationErrorMessage] = React.useState<string>();
    const today = dayjs();
    const tomorrow = today.add(1, 'day');
    const invalidExpiration = expirationDate === 'N/A';
    const staticExpirationString = dayjs(staticExpiration).format("MM/DD/YYYY")
    const staticDoBString = dayjs(staticDoB).format("MM/DD/YYYY")

    const clearErrors = (dob: boolean = false) => {
        if (dob) {
            setDobError(false);
            setDobErrorMessage('');
        } else {
            setExpirationError(false);
            setExpirationErrorMessage('');
        }
    };

    React.useEffect(() => {
        const currentString = dayjs(expirationDate).format("MM/DD/YYYY")
        const currentDoBString = dayjs(dob).format("MM/DD/YYYY")
        if ((!dobError && !expirationError) && ((staticExpirationString !== currentString) || (staticDoBString !== currentDoBString))) {
            setDisabled(false)
        } else { setDisabled(true) };
    }, [expirationError, dobError, expirationDate, dob]);

    const handleOnChange = (dateType: DateType, newDate?: Dayjs) => {
        if (!newDate) {
            switch (dateType) {
                case DateType.EXPIRATION_DATE:
                    setExpirationErrorMessage(buildDateErrorMessage(dateType, DateErrorType.REQUIRED));
                    setExpirationError(dateType === DateType.EXPIRATION_DATE);
                    break;
                case DateType.DOB:
                    setDobErrorMessage(buildDateErrorMessage(dateType, DateErrorType.REQUIRED));
                    setDobError(dateType === DateType.DOB);
                    break;
            }
            setDisabled(true);
            return;
        } else if (!newDate.isValid()) {
            switch (dateType) {
                case DateType.EXPIRATION_DATE:
                    setExpirationErrorMessage(buildDateErrorMessage(dateType, DateErrorType.INVALID));
                    setExpirationError(dateType === DateType.EXPIRATION_DATE);
                    break;
                case DateType.DOB:
                    setDobErrorMessage(buildDateErrorMessage(dateType, DateErrorType.INVALID));
                    setDobError(dateType === DateType.DOB);
                    break;
            }
            setDisabled(true)
            return;
        } else {
            switch (dateType) {
                case DateType.EXPIRATION_DATE:
                    if (newDate.isBefore(tomorrow, 'day')) {
                        setExpirationErrorMessage(buildDateErrorMessage(dateType, DateErrorType.PRECEDES));
                    } else if (newDate.isAfter(dayjs(staticExpiration), 'day')) {
                        setExpirationErrorMessage(buildDateErrorMessage(dateType, DateErrorType.EXCEDES, staticExpiration));
                    } else {
                        clearErrors();
                        const currentExpString = dayjs(newDate).format("MM/DD/YYYY")
                        const currentDoBString = dayjs(dob).format("MM/DD/YYYY")
                        if ((dobError || expirationError) || ((staticExpirationString === currentExpString) && (staticDoBString === currentDoBString))) {
                            setDisabled(true)
                            return
                        }
                        setDisabled(false)
                        return;
                    }
                    setExpirationError(true);
                    break;
                case DateType.DOB:
                    if (newDate.isAfter(today)) {
                        setDobErrorMessage(buildDateErrorMessage(dateType, DateErrorType.EXCEDES, today))
                    }
                    else {
                        clearErrors(true);
                        const currentExpString = dayjs(expirationDate).format("MM/DD/YYYY")
                        const currentDoBString = dayjs(newDate).format("MM/DD/YYYY")
                        if ((dobError || expirationError) || ((staticExpirationString === currentExpString) && (staticDoBString === currentDoBString))) {
                            setDisabled(true)
                            return
                        }
                        setDisabled(false)
                        return;
                    }
                    setDobError(true);
                    break;
            }
            setDisabled(true);
        }
    };

    return (
        <Container disableGutters sx={{ marginBottom: "70%", height: "45%", gap: '2rem', display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
            <EditSubTextRow>
                <EditSubTextDisplay title="FULL NAME" value={fullName} />
                <DatePicker
                    label="Date of Birth"
                    name="dob"
                    value={dayjs(dob)}
                    maxDate={today}
                    slotProps={{
                        textField: {
                            placeholder: "MM/DD/YYYY",
                            onChange: (newValue: any) => {
                                handleOnChange(DateType.DOB, newValue as Dayjs)
                                dispatchFormValues({ type: EditSubscriptionAction.DOB, value: newValue })
                            },
                            error: dobError,
                            helperText: dobErrorMessage
                        },
                        field: {
                            clearable: true,
                            onClear: () => {
                                dispatchFormValues({ type: EditSubscriptionAction.DOB, value: '' })
                            }
                        }
                    }}
                    sx={{ minWidth: "50%" }}
                    onChange={(newValue) => {
                        clearErrors(true);
                        dispatchFormValues({ type: EditSubscriptionAction.DOB, value: newValue })
                    }}
                />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="Rapback ID" value={rapbackSubId} />
                <EditSubTextDisplay title="PRIMARY ORI" value={ori} />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="SUBSCRIPTION START DATE" value={dayjs(startDate).format("MM/DD/YYYY")} />
                <DatePicker
                    label="Expiration Date"
                    name="expirationDate"
                    minDate={tomorrow}
                    maxDate={dayjs(staticExpiration)}
                    value={dayjs(expirationDate)}
                    disabled={invalidExpiration || (dayjs(staticExpiration) < dayjs()) || (staticExpiration && !dayjs(staticExpiration).isValid() && !invalidExpiration)}
                    slotProps={{
                        textField: {
                            onChange: (newValue: any) => {
                                handleOnChange(DateType.EXPIRATION_DATE, newValue as Dayjs)
                                dispatchFormValues({ type: EditSubscriptionAction.EXPIRATION_DATE, value: newValue })
                            },
                            placeholder: "MM/DD/YYYY",
                            error: expirationError || invalidExpiration,
                            helperText: invalidExpiration ? buildDateErrorMessage(DateType.EXPIRATION_DATE, DateErrorType.ADMINISTRATOR) : expirationErrorMessage
                        },
                        field: {
                            clearable: true,
                            onClear: () => {
                                dispatchFormValues({ type: EditSubscriptionAction.EXPIRATION_DATE, value: '' })
                            }
                        }
                    }}
                    sx={{ minWidth: "50%" }}
                    onChange={(newValue) => {
                        clearErrors();
                        dispatchFormValues({ type: EditSubscriptionAction.EXPIRATION_DATE, value: newValue })
                    }}
                />
            </EditSubTextRow>
            <EditSubTextRow>
                <EditSubTextDisplay title="Confirmation" value={confirmation} />
                <EditSubTextDisplay title="Status" value={status} />
            </EditSubTextRow>
        </Container>
    )
}