/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { NotesList } from "./components/NotesList";
import React, { useCallback, useEffect, useState } from "react";
import useNotes from "../../hooks/useNotes";
import he from 'he';
import { urls } from '../../urls';
import NotesService from '../../utils/notesService';
import { useParams } from 'react-router-dom';
import { useAudit } from '../../hooks/useAudit';
import {
  Divider,
  Typography,
  MenuItem,
  TextField,
  CircularProgress,
  InputAdornment,
  Grid,
  FormControl,
  Button,
  IconButton
} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NotesProps } from "@propTypes/index";
import { DividerStyle } from "customStyles/common";
import { NotesStyle } from 'customStyles';
import { Auth } from 'aws-amplify';


const NoNotes: React.FC<any> = () => {
  return (
    <Grid sx={NotesStyle.noNotesStyle}>
      <InboxIcon sx={{ color: 'text.primary' }} />
      <Typography variant="subtitle2" color='text.primary'>
        No Notes
      </Typography>
    </Grid>
  );
};

export const Notes: React.FC<NotesProps> = ({ userRole, personalInfo, usersPermissions }) => {
  const { notesJSON, fetchStatus } = useNotes("note");
  const [notes, setNotes] = useState<Array<object>>(notesJSON);
  const [selectedNoteId, setSelectedNoteId] = useState<string>('');
  const [hasBlankNote, setHasBlankNote] = useState<boolean>(false);
  const [newNotes, setNewNotes] = useState<string>('');
  const { registrationId = '' } = useParams<{ registrationId: string }>();
  const [currentUser, setCurrentUser] = useState<any>()

  const url = urls.NOTES;
  let notesService = new NotesService(registrationId, 'note', url);
  const { addAuditEvent } = useAudit();

  const getUserInfo = async () => {
    let res = await Auth.currentUserInfo()
    if (res) setCurrentUser(res.username)
  }

  useEffect(() => {
    getUserInfo()
  }, [])



  const encodeText = (text: string) => {
    const sanitizedInput = text.replace(/[&<>"']/g, '');
    const encodedText = he.encode(sanitizedInput);
    console.log('encodedText: ', encodedText);
    return encodedText;
  };

  const handleTextInput = (e: any) => {
    // New notes into the textfield
    let notesInput = e.target.value
    setNewNotes(notesInput);
  }

  const handleClearText = (e: any) => {
    setNewNotes('');
  }

  const saveNewNotes = async (newNotes: string) => {
    const temporaryId = `${Date.now()}`;
    try {
      if (newNotes !== '') {
        const newNote = {
          temporaryId,
          attachments: [],
          body: newNotes,
          creator: currentUser,
          datecreated: Date.now(),
        };
        notesService.createNote(encodeText(newNotes)).then((createdNote) => {
          const updateNotes = [
            { ...newNote, temporaryId: createdNote.id }, ...notes
          ]
          setNotes(updateNotes);
          addAuditEvent('Created', "Notes / New Note", new Date(), registrationId)
        });
      }
      setNewNotes('');
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  const focusTextArea = () => {
    setTimeout(() => {
      // inputRef.current?.focus();
    }, 0);
  };

  const [view, setView] = useState('All Notes');

  const sortNotesView = (e: any) => {
    setView(e.target.value)
  }

  const handleSelectedNoteChange = useCallback((id: string) => {
    setSelectedNoteId(id);
    focusTextArea();
  }, [])

  useEffect(() => {
    // Placing newest to oldest notes
    const sortedNotes = notesJSON.sort((a: any, b: any) => {
      const dateA = new Date(a.datelastmodified || a.datecreated);
      const dateB = new Date(b.datelastmodified || b.datecreated);
      return dateB.getTime() - dateA.getTime();
    });
    setNotes(sortedNotes);
    handleSelectedNoteChange(sortedNotes[0]?.id || null);
  }, [fetchStatus, handleSelectedNoteChange, notesJSON]);


  const isLoading = fetchStatus === "loading" || fetchStatus === "idle";
  const hasNotes = notes.length;

  if (isLoading) {
    return (
      <Grid sx={NotesStyle.notesLoadingContainer}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid sx={NotesStyle.notesBodyStyle} >
        {usersPermissions?.["createNewNotes"] &&
          <>
            <TextField multiline fullWidth label="Notes" variant='outlined' maxRows={4} minRows={2}
              placeholder="Enter note text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    {!newNotes}
                  </InputAdornment>
                ),
                endAdornment: newNotes ? (
                  <InputAdornment position="end">
                    <IconButton size='small' onClick={handleClearText} sx={{ color: 'primary' }}>
                      <HighlightOffIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              value={newNotes}
              onChange={handleTextInput} />
            <Button variant="outlined" onClick={() => saveNewNotes(newNotes)} disabled={!!newNotes ? false : true} sx={{ color: 'text.primary' }}>
              Save Note
            </Button>
            <Divider style={{ ...DividerStyle, marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
          </>}
        <Grid display='flex' justifyContent='space-between' width='100%' alignItems='center'>
          <Typography variant='subtitle2' color='text.primary' gutterBottom={false}>Notes</Typography>
          {!!hasNotes &&
            <span>
              <FormControl>
                <TextField aria-label='filter select'
                  sx={{ fontFamily: 'Montserrat', width: '12.5rem' }}
                  select
                  label="Filter By"
                  defaultValue={'All Notes'}
                  onChange={sortNotesView}
                  value={view === 'All Notes' ? 'All Notes' : 'My Notes'}
                >
                  <MenuItem value='All Notes' sx={{ fontFamily: 'Montserrat' }} aria-label="all notes" >
                    All Notes
                  </MenuItem>
                  <MenuItem value={'My Notes'} sx={{ fontFamily: 'Montserrat' }} aria-label="my notes">
                    My Notes
                  </MenuItem>
                </TextField>
              </FormControl>
            </span>
          }
        </Grid>
        <Grid sx={NotesStyle.notesContainerStyle}>
          {!hasNotes ? <NoNotes /> : null}
          <NotesList
            usersPermissions={usersPermissions}
            userRole={userRole}
            personalInfo={personalInfo}
            notes={notes}
            setNotes={setNotes}
            selectedNoteIdState={[selectedNoteId, handleSelectedNoteChange]}
            blankNoteState={[hasBlankNote, setHasBlankNote]}
            currentUser={currentUser}
            view={view}
          />
        </Grid>
      </Grid>
    </>
  );
};
