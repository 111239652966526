/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  Button,
  CardHeader,
  Alert,
  Grid,
  AlertTitle,
  Typography,
  CardContent,
  List,
  ListItem,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import copy from "copy-to-clipboard";
import { useMUITheme } from "../../theme/ThemeProvider";
import { MFAProps } from '@propTypes/MFAProps';
import { LoginStyles } from "customStyles";
import { useLoginStore } from "state/LoginStore";
import { getBackgroundColor, getBorderColor, getIconColor } from "customStyles/common";
import { errorTypes } from "../../utils/errorTypes";
import { SetUpMFAComponent, MFATextInput, MFAPwButton, MFAPwField } from "./components";
import lightLogo from '../../assets/logo/IDEMIA-purple.png';
import darkLogo from '../../assets/logo/IDEMIA-white.png';

export const MFAPasswordReset: React.FC<MFAProps> = ({
  userName,
  resetPassword,
  cancel,
  stateLogo,
}) => {
  const {
    handleMFASubmit,
    user,
    mfa,
    authCode,
    userForgotPassword,
    handleResetPassword,
    authFlowError,
    sendForgotPassCode,
    forceResetPassword,
    cancelFlow,
    loginLoading,
    authFlowErrorType,
    loading,
    setAuthFlowError
  } = useAuth();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPass, setShowConfirmedPass] = useState(false);
  const [verifyCode, setVerifyCode] = useState<string>("");
  const [mfaVerify, setMFAVerify] = useState(false);
  const [setupMFA, setSetupMFA] = useState(false);
  const [userResetPass, setUserResetPass] = useState<boolean>(false);
  const [forceResetPass, setForceResetPass] = useState(false);
  const [getUserName, setGetUserName] = useState(false);
  const [userNameValue, setUserNameValue] = useState<string>("");
  const [currentPass, setCurrentPass] = useState<string>("");
  const [newPass, setNewPass] = useState<string>("");
  const [finalPassword, setPassword] = useState<string>("")
  const [pwValidationMsg, setPwValidationMsg] = useState('')
  const [verificationMsg, setVerificationMsg] = useState('');
  const [inputId, setInputId] = useState("");
  const [inputLabel, setInputLabel] = useState("");
  const [inputName, setInputName] = useState("");
  const [inputKeyPress, setInputKeyPress] = useState(() => {})
  const [buttonLabel, setButtonLabel] = useState("");

  const inputRef = useRef<any>(null);
  const btnRef = useRef<any>(null);
  const { mode } = useMUITheme();
  const isDark = mode === 'dark';

  // Login Store:
  const labelDescriptionText = useLoginStore((state) => state.labelDescriptionText)
  const labelText = useLoginStore((state) => state.labelText)
  const errorMessage = useLoginStore((state) => state.errorMessage)
  const errorDescription = useLoginStore((state) => state.errorDescription)
  const copySuccess = useLoginStore((state) => state.copySuccess)

  const setLabelDescriptionText = useLoginStore((state) => state.setLabelDescriptionText)
  const setLabelText = useLoginStore((state) => state.setLabelText)
  const setCopySuccess = useLoginStore((state) => state.setCopySuccess)
  const setErrorMessage = useLoginStore((state) => state.setErrorMessage)
  const setErrorDescription = useLoginStore((state) => state.setErrorDescription)

  const severity = useLoginStore((state) => state.severity)
  const setSeverity = useLoginStore((state) => state.setSeverity)
  const openAlert = useLoginStore((state) => state.openAlert)
  const setOpenAlert = useLoginStore((state) => state.setOpenAlert)

  useEffect(() => {
    if (mfaVerify)
      inputRef.current?.focus({
        cursor: "start",
      });
  }, [mfaVerify]);

  useEffect(() => {
    // different labels for the login flow
    if (mfa && handleMFASubmit && !authCode) {
      setMFAVerify(true);
      setLabelText("Two-Factor Authenticator");
      setLabelDescriptionText("Please enter the code displayed on your authenticator application.")
      setButtonLabel("VERIFY")
      setInputId("verification-code");
      setInputLabel("Verification Code");
      setInputName("Verification Code");
      setInputKeyPress((e) => handleKeypress(e));
    } else if (mfa && handleMFASubmit && authCode) {
      setSetupMFA(true);
      setLabelText("Enable Two-Factor Authentication");
      setLabelDescriptionText("Your organization requires you to set up two-factor authentication. Use a time-based authenticator to scan the QR code below or manually enter the key.")
      setButtonLabel("CONTINUE")
      setInputId("verification-code");
      setInputLabel("Verification Code");
      setInputName("Verification Code");
      setInputKeyPress((e) => handleKeypress(e));
    } else if (userForgotPassword && handleResetPassword) {
      setLabelText("Change Password");
      setLabelDescriptionText("Enter the code that was sent to your email. Your new password must:")
      setGetUserName(false);
      setUserResetPass(true);
      setButtonLabel('CHANGE PASSWORD')
      setInputId("reset-pw-code");
      setInputLabel("Reset Password Code");
      setInputName("reset-pw-code");
    } else if (forceResetPassword && handleResetPassword) {
      setLabelText("Create New Password");
      setLabelDescriptionText("A strong password helps prevent unauthorized access to your account. Your password must:")
      setForceResetPass(true);
      setButtonLabel('CREATE PASSWORD')
    } else if (userName && !userForgotPassword) {
      setLabelText("Forgot Password?");
      setLabelDescriptionText("Enter the username associated with your account and we'll send you a code to verify your account.");
      setGetUserName(true);
      setButtonLabel("SEND RESET CODE")
      setInputId("userName");
      setInputLabel("Username");
      setInputName("userName");
    } else if (!forceResetPassword) {
      setForceResetPass(false);
    } else if (!userForgotPassword) {
      setUserResetPass(false);
    } else if (mfaVerify) {
      setMFAVerify(false)
    }
  }, [
    mfa,
    authCode,
    user,
    userName,
    userForgotPassword,
    forceResetPassword,
    getUserName,
    handleMFASubmit,
    handleResetPassword,
    cancel
  ]);

  const passwordRequirements: string[] = [
    'Be a minimum of 8 characters in length',
    'Contain at least 1 number',
    'Contain at least 1 special character',
    'Contain at least 1 uppercase letter',
    'Contain at least 1 lowercase letter'
  ];

  const updateCode = (e: any) => {
    let code = e.target.value;
    if (code === "") {
      setOpenAlert(false);
      setSeverity("")
    }
    setVerifyCode(code);
  };

  const updateUserName = (e: any) => {
    setUserNameValue(e.target.value);
  };

  const handleSendVerification = async () => {
    if (userNameValue) sendForgotPassCode?.(userNameValue);
    else setErrorMessage("User name empty!");
    setGetUserName(false);
  };

  const updateCurrentPass = (value: string) => {
    setCurrentPass(value);
  };

  useEffect(() => {
    const message = authFlowError!
    const type = authFlowErrorType!

    if (errorTypes[message]) {
      setErrorMessage(errorTypes[message].title);
      setErrorDescription(errorTypes[message].description);
    } else if (errorTypes[type]) {
      setErrorMessage(errorTypes[type].title);
      setErrorDescription(errorTypes[type].description);
    } else {
      setErrorMessage(message);
      setErrorDescription('');
    }
  }, [authFlowErrorType, errorDescription, authFlowError])

  console.log('errormessage', errorMessage);

  useEffect(() => {
    const lengthValid = currentPass.length >= 8;
    const hasNumber = /\d/.test(currentPass);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(currentPass);
    const hasUpperCase = /[A-Z]/.test(currentPass);
    const hasLowerCase = /[a-z]/.test(currentPass);
    const onlyNumbersForVerification = /^\d*$/.test(verifyCode);
    if (forceResetPass || userResetPass) {
      if (!lengthValid) setErrorMessage(`Your Password Must  ${passwordRequirements[0]}`)
      if (!hasNumber) setErrorMessage(`Your Password Must ${passwordRequirements[1]}`)
      if (!hasSpecialCharacter) setErrorMessage(`Your Password Must ${passwordRequirements[2]}`)
      if (!hasLowerCase) setErrorMessage(`Your Password Must ${passwordRequirements[4]}`)
      if (!hasUpperCase) setErrorMessage(`Your Password Must ${passwordRequirements[3]}`)
      if (!onlyNumbersForVerification) setVerificationMsg('Verification code must be numbers')
      else setVerificationMsg('')
      if (lengthValid && hasNumber && hasSpecialCharacter && hasLowerCase && hasUpperCase && (currentPass !== newPass)) setPwValidationMsg("Your password do not match")
      else setPwValidationMsg('');
      if (currentPass.length === 0 || (hasUpperCase && hasLowerCase && hasSpecialCharacter && hasNumber && lengthValid)) setErrorMessage('');
    }
  })

  const updateNewPass = (value: string) => {
    setNewPass(value);
  };

  console.log('debug error message: ', errorMessage);
  console.log('debug error description: ', errorDescription);

  useEffect(() => {
    if (currentPass && newPass) {
      if (currentPass === newPass) {
        setPassword(newPass);
        setErrorMessage("");
      } else {
        setErrorMessage("Passwords do not match.");
      }
    }
  }, [currentPass, newPass]);

  const handleKeypress = (e: any) => {
    console.log("enter pressed");
    if (setAuthFlowError) {
      setAuthFlowError('');
    }
    if (e?.keyCode === 13) {
      if (setupMFA || mfaVerify) {
        handleMFASubmit?.(verifyCode);
      } else if (forceResetPass || userForgotPassword) {
        resetPassword?.(
          finalPassword,
          userResetPass ? verifyCode : null,
          userNameValue
        );
      } else if (getUserName) {
        handleSendVerification();
      }
    }
  };

  const handleCancel = () => {
    cancel(false);
    cancelFlow();
    setOpenAlert(false)
    setSeverity("")
  }

  const copyText = async () => {
    let copyText = authCode.text
    let isCopy = copy(copyText)
    if (isCopy) {
      setCopySuccess("Copied!");
    }
  }

  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmedPass(!showConfirmedPass);
  const handleMouseDownConfirmPassword = () => setShowConfirmedPass(!showConfirmedPass);

  const handleSubmit = (e: any) => {
    if (setAuthFlowError) {
      setAuthFlowError('');
    }
    e.preventDefault();
    if (setupMFA || mfaVerify) {
      handleMFAclick(verifyCode)
    } else if (forceResetPass || userForgotPassword) {
      resetPassword?.(finalPassword, userResetPass ? verifyCode : null, userNameValue)
    } else if (getUserName) {
      handleSendVerification()
    }
  }

  const handleMFAclick = (verifyCode: string) => {
    handleMFASubmit?.(verifyCode)
  };

  const footerButtonsStyle = {
    display: 'flex',
    padding: '0.5rem 0 0',
    justifyContent: 'flex-end',
    alignitems: 'flex-start',
    gap: setupMFA ? '24px' : '16px',
    alignself: 'stretch',
    width: '100%'
  }

  const alertStyle = {
    backgroundColor: getBackgroundColor(severity),
    mb: '1rem',
    borderColor: getBorderColor(severity),
    border: `1px solid`,
    '.MuiAlert-icon': {
      color: getIconColor(severity),
    },
    width: '100%'
  };

  const listOfPWRequirements = () => {
    return (
      <List sx={LoginStyles.pwRequirementStyle}>
        {passwordRequirements.map((list, index) => (
          <ListItem sx={{ display: 'list-item', padding: 0 }} key={index}>
            <Typography variant='body2' color='text.secondary' >
              {list}
            </Typography>
          </ListItem>
        ))}
      </List>
    )
  }

  const isButtonDisabled = () => {
    if (setupMFA || mfaVerify) {
      return loading ? true : verifyCode ? false : true;
    }  
    if (userResetPass) {
      return loading ? true : (verifyCode && !errorMessage && !pwValidationMsg && newPass && currentPass) ? false : true;
    }
    if (forceResetPass) {
      return loading ? true : (!errorMessage && !pwValidationMsg && currentPass && newPass) ? false : true;
    } 
    if (getUserName) {
      return loading ? true : userNameValue ? false : true
    }
    return true;
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenAlert(false)
      setErrorDescription("")
      setErrorMessage("")
    }, 10000)
  })

  return (
    <>
      <form style={LoginStyles.formStyle} onSubmit={handleSubmit} >
        <CardHeader
          title={labelText}
          titleTypographyProps={{
            variant: 'h5', color: 'text.primary', mb: '0.5rem'
          }}
          subheader={labelDescriptionText}
          subheaderTypographyProps={{
            variant: 'body2', color: 'text.secondary'
          }} />

        {(forceResetPass || userResetPass) && listOfPWRequirements()}

        <CardContent sx={LoginStyles.cardContentStyle}>
          {setupMFA && <SetUpMFAComponent authCode={authCode} copyText={copyText} copySuccess={copySuccess} />}
          {openAlert &&
            <Alert severity={severity} sx={alertStyle}  >
              <AlertTitle color={getIconColor(severity)}>
                {errorMessage}
              </AlertTitle>
              <Typography color={getIconColor(severity)} variant='body2'>
                {errorDescription}
              </Typography>
            </Alert>
          }
          {
            (mfaVerify || setupMFA || getUserName || userResetPass) && (
              <MFATextInput
                id={inputId}
                label={inputLabel}
                name={inputName}
                onKeyPress={inputKeyPress}
                textInput={mfaVerify || setupMFA || userResetPass ? updateCode : updateUserName}
                required={true}
                inputRef={inputRef}
                error={(userResetPass && verificationMsg) ? true : false}
                helperText={(userResetPass ? verificationMsg : "")}
                inputProps={(mfaVerify || setupMFA || userResetPass) ? {
                  inputMode: 'numeric',
                  pattern: "[0-9]*",
                  maxLength: 6
                } : {}}
                autoComplete={getUserName ? "Username" : ""}
                type="text"
                variant={mode === 'dark' ? "filled" : "outlined"}
              />
            )
          }
          {(forceResetPass || userResetPass) &&
            <>
              <MFAPwField
                id="new-password"
                label="New Password"
                name="password"
                onChange={(e) => updateCurrentPass(e.target.value)}
                type={showNewPassword ? "text" : "password"}
                inputRef={inputRef}
                autoComplete={getUserName ? "Username" : ""}
                variant={mode === 'dark' ? "filled" : "outlined"}
                error={errorMessage ? true : false}
                helperText={errorMessage}
                togglePW={handleClickShowNewPassword}
                toggleMouseDown={handleMouseDownNewPassword}
                showPassword={showNewPassword}
              />
              <MFAPwField
                id={"confirm-password"}
                label={"Confirm Password"}
                name={"confirm-password"}
                onChange={(e) => updateNewPass(e.target.value)}
                type={showConfirmedPass ? "text" : "password"}
                inputRef={inputRef}
                autoComplete={getUserName ? "Username" : ""}
                variant={mode === 'dark' ? "filled" : "outlined"}
                error={pwValidationMsg ? true : false}
                helperText={pwValidationMsg}
                togglePW={handleClickShowConfirmPassword}
                toggleMouseDown={handleMouseDownConfirmPassword}
                showPassword={showConfirmedPass}
              />
            </>
          }
          {(setupMFA || mfaVerify || getUserName || userResetPass || forceResetPass) && (
            <div style={footerButtonsStyle}>
              {(setupMFA || mfaVerify) && <MFAPwButton
                onClick={handleCancel}
                variant="outlined"
                color='primary'
                disabled={false}
                style={{ color: 'text.primary', width: setupMFA ? '50%' : "24%" }}
                label="CANCEL"
              />}
              <MFAPwButton
                variant="contained"
                type="submit"
                color='secondary'
                loading={loading}
                disabled={isButtonDisabled()}
                style={{ width: setupMFA ? '50%' : (getUserName || userResetPass || forceResetPass) ? "100%" : "21%" }}
                label={buttonLabel}
              />
            </div>
          )}
          {(userResetPass || forceResetPass || getUserName) &&
            <Button
              sx={{ padding: 0, fontSize: '0.8125rem' }}
              color='primary'
              variant="text"
              size="large"
              onClick={handleCancel}
              id="cancel"
            >
              <ArrowBackIcon fontSize='small' sx={{ mr: '0.25rem' }} />BACK TO SIGN IN
            </Button>
          }
          {(userForgotPassword || forceResetPass || getUserName) && (
            <Grid container justifyContent='center' alignItems='center' direction='column' mt='0.5rem'>
              <Typography variant="h3" color='text.primary'>{stateLogo}</Typography>
              <img src={isDark ? darkLogo : lightLogo} style={{ width: '86px' }} />
            </Grid>
          )}
        </CardContent>
      </form>
    </>
  );
};