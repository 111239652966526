/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { FC, useCallback, useEffect, useState } from "react";
import { useIdleTimer, workerTimers } from "react-idle-timer";
import { useKeyPressEvent } from "react-use";
import { TimeoutWarning } from "./timeout-warning";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import { useCookies } from "react-cookie";
import { useAudit } from "../../hooks/useAudit";
import { Auth } from "aws-amplify";
import { useAuth } from "../../hooks/useAuth";
import { getDomainName } from "../../utils/getDomainName";
import { useLocalStorage } from "../../common";
import { urls } from '../../urls'
import { useMUITheme } from "../../theme/ThemeProvider";
import { IdleTrackerProps } from '@propTypes/index';
import { useTableStore } from 'state/TableStore';
import { useUserStore } from "state/UserStore";
import { clear } from "console";

export const IdleTracker: FC<IdleTrackerProps> = ({
  idleTimeout,
  promptTimeout,
}) => {
  const userConfig = useUserStore(state => state.userConfig)
  const { loggedIn } = userConfig

  const [timeoutWarningVisible, setTimeoutWarningVisible] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [remaining, setRemaining] = useState<any>(0);
  const [open, setOpen] = useState(false);
  const [cookies, , removeCookie] = useCookies(["token"]);
  const { addAuditEvent } = useAudit();
  console.log(`debug group call running useUserCognito from idleTracker`)

  const authUrl = urls.AUTH;
  const { mode } = useMUITheme();

  const setUserActive = useTableStore(state => state.setUserActive)
  const setTableConfig = useTableStore(state => state.setTableConfig)
  const setUserConfig = useUserStore(state => state.setUserConfig)
  const resetUserStore = useUserStore(state => state.resetUserStore)



  const clearStorageRefresh = () => {
    if (process.env.NODE_ENV != 'production') {
      removeCookie("token", { path: "/", domain: getDomainName() });
    }
    localStorage.clear()
    localStorage.setItem("theme", mode);
    useUserStore.persist.clearStorage()
    useTableStore.persist.clearStorage()
    localStorage.setItem("theme", mode);
    setTimeout(() => {
      console.log('debug reset userStore')
      resetUserStore()
    }, 2000)
    window.location.reload()
  }

  const logoutTokenHandler = async () => {
    console.log(`debug logout idle logoutToken handler`)

    let body = {
      action: 'CLEAR_TOKEN',
    }
    const resp = await fetch(`${authUrl}/auth/token-handler`, {
      method: 'POST',
      body: JSON.stringify(body),
      credentials: "include"
    })
    console.log(`debug logout idle logoutToken out resp: `, resp)

    if (resp.ok) {
      console.log(`debug logout idle resp ok run clearStorageRefresh: `, clearStorageRefresh)

      clearStorageRefresh?.()
    } else {
      console.log('Error logging out')
    }
  }

  const handleLogout = useCallback(async () => {
    //console.log("debug logout idle: in handleLogout logout: ", { logout, clearStorageRefresh });

    setOpen(false);

    try {
      const { username } = await Auth.currentAuthenticatedUser();
      await addAuditEvent(
        "Logout",
        `${username} logged out of CARES dashboard at ${new Date()}`,
        new Date(),
        undefined
      );
      console.log(`debug logout idle username ${username}`)

    } catch (e: any) {
      console.log(`debug logout idle Error logging out`)
      console.log('Error logging out: ', e)
      clearStorageRefresh?.()
    }

    if (process.env.NODE_ENV != 'production') {
      console.log(`debug logout idle env ${process.env.NODE_ENV} running clearStorageRerfresh: `, clearStorageRefresh)

      clearStorageRefresh()
    } else {
      console.log(`debug logout idle in else logoutTokenHandler`)

      logoutTokenHandler()
    }

    //setTableConfig({})
  }, [clearStorageRefresh]);

  useEffect(() => {
    console.log("cookies", cookies);
  }, [cookies]);

  const handlePrompt = () => {
    if (loggedIn) {
      setTimeoutWarningVisible(true);
      setRemaining(promptTimeout);
      setOpen(true);
      setUserActive(false);
    }
  };
  const handleIdle = () => {
    if (loggedIn) {
      setIsExpired(true);
      setRemaining(0);
      sessionStorage.setItem('Session expired', "true");
      handleLogout();
      setUserActive(false);
    }
  };

  const handleActive = () => {
    setTimeoutWarningVisible(false);
    console.log('hello, am i active? ')
    setRemaining(0);
  };

  const onMessage = (data) => {
    switch (data.action) {
      case 'LOGOUT_USER':
        handleLogout()
        break
    }
  }

  const onAction = () => {
    setUserActive(true)
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { activate, getRemainingTime, isPrompted, message } = useIdleTimer({
    onAction,
    timeout: idleTimeout,
    promptBeforeIdle: promptTimeout,
    onPrompt: handlePrompt,
    onIdle: handleIdle,
    onActive: handleActive,
    timers: workerTimers,
    crossTab: true,
    stopOnIdle: true,
    leaderElection: true,
    syncTimers: 200,
    onMessage
  });

  const onLogoutClick = () => {
    message({ action: 'LOGOUT_USER' }, true)
  }

  const handleResume = () => {
    setTimeoutWarningVisible(false);
    activate();
    setOpen(false);
    setUserActive(true);
  };

  useKeyPressEvent(
    "Escape",
    () => timeoutWarningVisible && !isExpired && handleResume()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime()));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  useEffect(() => {
    if (loggedIn) activate();
  }, [activate, loggedIn]);

  if (!timeoutWarningVisible) {
    return null;
  }

  if (!loggedIn) return null;

  return (
    <Dialog open={open} onClose={handleResume} >
      <DialogTitle sx={{ width: '37.5rem' }}>TIMEOUT WARNING</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TimeoutWarning timeLeft={remaining} />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pr: '1.5rem', pb: '1rem' }}>
        <Button variant='outlined' color='primary' sx={{ color: mode === 'dark' ? '#fff' : null }} onClick={onLogoutClick}>LOG OUT</Button>
        <Button variant='contained' color='primary' onClick={handleResume}>STAY LOGGED IN</Button>
      </DialogActions>
    </Dialog>
  )
};

IdleTracker.defaultProps = {
  idleTimeout: 5 * 180_000, // 15 mins
  promptTimeout: 5 * 60_000, // 5 mins
};


